import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guide.scss';
import { WWWeaponEntry } from '../../../modules/ww/weapons/ww-weapon';

const WWGuidesSystem: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ww-guide'} game="ww">
      <ul className="breadcrumb">
        <li>
          <Link to="/wuthering-waves/">Wuthering Waves</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/wuthering-waves/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Game Systems</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ww/categories/category_systems.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Wuthering Waves Game Systems</h1>
          <h2>
            All Game Systems available in Wuthering Waves - learn what awaits
            you in the game!
          </h2>
          <p>
            Last updated: <strong>17/05/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Game Systems" />
        <p>
          This guide contains information about various systems available in
          Wuthering Waves.
        </p>
        <SectionHeader title="Union Level" />
        <StaticImage
          src="../../../images/ww/generic/system_6.webp"
          alt="Guide"
        />
        <p>
          <strong>Union Level is your overall account level</strong> and can be
          increased by obtaining Union EXP. Union EXP can primarily be earned by
          completing in-game missions and events as well as spending Waveplate
          (the game’s stamina system).
        </p>
        <p>
          As your Union Level increases, you will unlock more game functions
          such as Daily Activities as well as increasing the number of times
          that you can ascend your Resonators and Weapons, increasing their
          level cap.{' '}
          <strong>
            Additionally, the difficulty of the enemy in the overworld will also
            increase once you reach certain Union Level milestones. The maximum
            Union level is 60.
          </strong>
        </p>
        <p>
          As Union Level increases, the SOL3 Phase also increases, making
          enemies more challenging while also increasing rewards. SOL3 Phase
          increases every 10 Union Level.{' '}
          <strong>
            You can change your SOL3 Phase between the ones you unlocked every
            24 hours.
          </strong>
        </p>
        <h5>Waveplate</h5>
        <StaticImage
          src="../../../images/ww/generic/system_4.webp"
          alt="Guide"
        />
        <p>
          <strong>Waveplate is the stamina system of Wuthering Waves</strong>.
          It is primarily used to obtain resources and upgrade materials from
          the various resource and boss dungeons in the game. Waveplate is
          generated at a rate of 1 per 6 minutes. You can store up to a maximum
          of 240. A total of 240 Waveplate can be generated every day.
        </p>
        <p>
          You can also replenish your Waveplate by using Crystal Solvent (reward
          from various missions) or Astrites. Crystal Solvent is an in-game
          consumable that when used will give you 60 Waveplate immediately.
        </p>
        <SectionHeader title="Quests" />
        <StaticImage
          src="../../../images/ww/generic/system_1.webp"
          alt="Guide"
        />
        <p>Quests in Wuthering Waves come in a couple of different variety:</p>
        <ul>
          <li>
            <strong>Main:</strong>
          </li>
          <ul>
            <li>
              They are denoted with a yellow color in your quest log and on the
              mini map.
            </li>
            <li>
              They reward Astrites, the game’s gacha currency, and huge amounts
              of Union EXP.
            </li>
          </ul>
          <li>
            <strong>Companion:</strong>
          </li>
          <ul>
            <li>
              Companion Quests are side quests for specific Resonators. These
              are generally missions that will provide you with more background
              and lore on the Character that they are centered on. They are
              denoted with a purple color in your quest log and on the mini map.
            </li>
            <li>They reward Astrites and Union EXP.</li>
          </ul>
          <li>
            <strong>Side:</strong>
          </li>
          <ul>
            <li>
              These are Side Quests as the name suggests. They are denoted with
              a blue color in your quest log and on the mini map. Some will
              reward Astrites.
            </li>
          </ul>
          <li>
            <strong>Exploration:</strong>
          </li>
          <ul>
            <li>
              Exploration Quests are regional quests. These introduce you to new
              regions while providing lore. They are denoted with a green color
              in your quest log and on the mini map.
            </li>
          </ul>
          <li>
            <strong>Activities:</strong>
          </li>
          <ul>
            <li>Activities are your daily missions.</li>
            <li>
              You will receive a semi-randomized set of 7 Activities every day.
            </li>
            <li>
              Each Training mission will provide you with 10 or 20 Activity
              Points and you need to gather 100 in total to obtain all daily
              rewards which includes a total of 60 Astrites as well as some
              upgrade materials.
            </li>
          </ul>
        </ul>
        <SectionHeader title="Drifting Inscription" />
        <StaticImage
          src="../../../images/ww/generic/system_2.webp"
          alt="Guide"
        />
        <p>
          Drifting Inscription is a set of beginner missions that are designed
          to guide the player’s progression at the start of the game. Completing
          these missions will reward you with various resources.
        </p>
        <p>
          There are 6 pages of beginner missions in the Drifting Inscription.
        </p>
        <SectionHeader title="Trophies" />
        <StaticImage
          src="../../../images/ww/generic/system_3.webp"
          alt="Guide"
        />
        <p>
          As the name suggests, Trophies are a collection of tasks that the
          player can complete to earn a varying amount of Astrites
        </p>
        <SectionHeader title="Pioneer Podcast" />
        <StaticImage
          src="../../../images/ww/generic/system_5.webp"
          alt="Guide"
        />
        <p>
          <strong>Pioneer Podcast is the battle pass of Wuthering Waves</strong>
          . It has a free and premium (that can be unlocked with real money)
          path. Players can get rewards from these paths by completing daily,
          weekly, and periodical missions. Pioneer Podcast resets periodically
          (most likely every 6 weeks).
        </p>
        <p>
          The price of the{' '}
          <strong>
            Premium version (called Insider Channel) was set at $10 during the
            CBT
          </strong>
          , and you also were able to buy an advanced version that unlocked some
          of the rewards early for $20. Furthermore, the Insider Channel comes
          with a <strong className="ww-color-4">4★</strong>{' '}
          <strong>weapon selector</strong> and those weapons can only be
          obtained from it (so it's the same as in Honkai: Star Rail).
        </p>
        <p>Here's the list of available weapons:</p>
        <div className="ww-weapons-container">
          <div>
            <WWWeaponEntry name="Thunderbolt" level={[1]} mode="icon" />
          </div>
          <div>
            <WWWeaponEntry name="Augment" level={[1]} mode="icon" />
          </div>
          <div>
            <WWWeaponEntry name="Stonard" level={[1]} mode="icon" />
          </div>
          <div>
            <WWWeaponEntry name="Lumingloss" level={[1]} mode="icon" />
          </div>
          <div>
            <WWWeaponEntry name="Autumntrace" level={[1]} mode="icon" />
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default WWGuidesSystem;

export const Head: React.FC = () => (
  <Seo
    title="Game Systems | Wuthering Waves | Prydwen Institute"
    description="All Game Systems available in Wuthering Waves - learn what awaits you in the game!"
    game="ww"
  />
);
